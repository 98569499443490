
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n/types';
import { routesNames } from '@/router';

import USER from '@/store/modules/UserModule';
import CART from '@/store/modules/CartModule';
import BOOKING from '@/store/modules/BookingModule';
import MISC_DATA from '@/store/modules/MiscDataModule';
import MAP from '@/store/modules/MapModule';

import { IPartner } from '@/types/misc_data';

export default Vue.extend({
	name: 'Home',
	middleware: 'fetchLandingPages',
	computed: {
		USER: () => USER,
		CART: () => CART,
		BOOKING: () => BOOKING,
		MISC_DATA: () => MISC_DATA,
		MAP: () => MAP,

		minTruckPrice(): number {
			let price = this.MISC_DATA.prices.starting_price;

			if (this.MISC_DATA.truck_min_price.L.price_6 !== 0 && this.MISC_DATA.truck_min_price.L.price_6 < price) {
				price = this.MISC_DATA.truck_min_price.L.price_6;
			}
			if (this.MISC_DATA.truck_min_price.XL.price_6 !== 0 && this.MISC_DATA.truck_min_price.XL.price_6 < price) {
				price = this.MISC_DATA.truck_min_price.XL.price_6;
			}
			if (this.MISC_DATA.truck_min_price.XXL.price_6 !== 0 && this.MISC_DATA.truck_min_price.XXL.price_6 < price) {
				price = this.MISC_DATA.truck_min_price.XXL.price_6;
			}

			return price;
		},

		currentLPData(): any {
			return this.MISC_DATA.current_LP_data;
		},

		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		showLpLocationMapLink(): boolean {
			return this.$route.name === routesNames.landing_page && this.locationSite;
		},

		locationSite(): any {
			if (!this.currentLPData || !this.currentLPData.sites || this.currentLPData.sites.length < 1) {
				return null;
			}
			const site = this.currentLPData.sites[0];
			return site;
		},

		locationSiteMapLink(): string | null {
			if (!this.locationSite) {
				return null;
			}
			const coordinates = `${this.locationSite.lat},${this.locationSite.lng}`;
			return `https://maps.google.com/maps/dir/?api=1&destination=${encodeURIComponent(coordinates)}`;
		},

		currentPartner(): IPartner | null {
			const partners = this.MISC_DATA.partners;
			const partner_routes = partners.map((p: IPartner) => {
				return p.route_name;
			});
			const is_partner_route = partner_routes.includes(this.$route.name as routesNames);
			const current_partner = partners.find((p: IPartner) => this.$route.name === p.route_name);

			if (!is_partner_route || !current_partner) {
				return null;
			}

			return current_partner;
		},

		showOfficialPartners(): boolean {
			const partners = this.MISC_DATA.partners as any;
			return partners.length > 0;
		},

		currentReviews(): { count: number; value: number } {
			// const lp_reviews: boolean = this.$route.name === routesNames.landing_page && this.currentLPData && this.currentLPData.reviews.count;

			const current_count = MISC_DATA.basedata.reviews.count;
			const current_value = MISC_DATA.basedata.reviews.value;

			return {
				count: current_count > 25 ? current_count : 25,
				value: current_value > 4.4 ? current_value : 4.4
			};
		},

		titleName(): string | TranslateResult {
			if (this.currentPartner) {
				return this.$t(`${this.currentPartner.t_key}.home_title`);
			}

			if (this.$route.name === routesNames.landing_page && this.currentLPData && this.currentLPData.name) {
				return this.$t('general.in') + ' ' + (this.currentLPData.name || 'deiner Nähe');
			}

			return this.$t('home.from_just', {
				price: this.$n(this.minTruckPrice, this.isInt(this.minTruckPrice) ? 'currencyShort' : 'currency')
			});
		},

		videoId(): string {
			if (this.$route.name === routesNames.landing_page && this.currentLPData && this.currentLPData.youtube_id) {
				return this.currentLPData.youtube_id;
			}
			return this.MISC_DATA.data_per_bu.youtube_id;
		},

		currentLanguage(): string {
			return this.$i18n.locale.split('-')[0];
		},

		advantageAnchorId(): string {
			return (this.$t('general.advantages') as any).normalize('NFD').replace(/\p{Diacritic}/gu, '');
		},

		pricesAnchorId(): string {
			return (this.$t('general.prices') as any).normalize('NFD').replace(/\p{Diacritic}/gu, '');
		},

		showDateTimePickerSection(): boolean {
			return this.currentPartner !== null;
		},

		isLandingPage(): boolean {
			return this.$route.name === routesNames.landing_page;
		}
	},

	async mounted() {
		window.addEventListener('load', this.onPageLoad);

		if (this.currentPartner) {
			this.$logger.console({
				message: `Current partner route - Requesting to get locations data - ID: ${this.currentPartner.id}`
			});
			if (
				!(await this.MISC_DATA.GET_PARTNER_LOCATIONS({
					partner_id: this.currentPartner.id,
					bu: this.$getDomain()
				}))
			) {
				this.$router.replace({ name: routesNames.home });
			}
		}

		this.MISC_DATA.GET_TRUCK_MIN_PRICES(this.$getDomain());

		// Clear booking no matter what - should be fine ?
		this.BOOKING.CLEAR_BOOKING_STORED();
		this.CART.CLEAR_BOOKING_STORED();
	},

	beforeDestroy() {
		window.removeEventListener('load', this.onPageLoad);
	},

	methods: {
		scrollTop(): void {
			window.scrollTo(0, 0);
		},

		isInt(n: any): boolean {
			return Number(n) === n && n % 1 === 0;
		},

		openMapDirectionToSite(): void {
			if (!this.locationSiteMapLink) {
				return;
			}

			window.open(this.locationSiteMapLink, '_blank');
		},

		onPageLoad() {
			if (this.$route.hash) {
				const hash = this.$route.hash.split('?')[0];
				this.$vuetify.goTo(hash);
			}
		},

		async openConfirmDialog() {
			if (this.$isDomain('de')) {
				if (
					!(await this.$dialogs.confirm(this.$t('cust.cookie.maps_redirect.text') as string, this.$t('cust.cookie.maps_redirect.title') as string, {
						color: 'grey--text text--darken-3',
						right_btn_text: this.$t('actions.next') as string,
						left_btn_text: this.$t('actions.abort') as string
					}))
				) {
					return;
				}
			}

			this.openMapDirectionToSite();
		}
	}
});
